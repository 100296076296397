// .join-section {
//   --width: 100px;
//   --height: 100px;
//   --border-radius: 50%;
//   position: fixed;
//   width: var(--width);
//   height: var(--height);
//   z-index: 100;
//   right: 50px;
//   bottom: 50px;
// }

// .join-container {
//   display: flex;
//   justify-content: center;
//   align-items: flex-end;
//   background-color: var(--red-400);
//   border-radius: var(--border-radius);
//   transition: all 0.5s ease;
//   width: var(--width);
//   height: var(--height);
//   transform-origin: bottom left;
//   cursor: pointer;
// }

// .join-container.active {
// }

// // .join-container.inactive {
// //   transform: translateY(0);
// //   height: inherit;
// // }

// .button {
//   display: grid;
//   place-content: center;
//   font-size: 12px;
//   width: var(--width);
//   height: var(--height);
//   font-size: 22px;
//   font-family: Haettenschweiler, "Arial Narrow Bold", sans-serif;
//   font-weight: 900;
// }

// // .button.inactive {
// //   visibility: visible;
// // }

// // .button.active {
// //   visibility: hidden;
// // }

// .button text {
//   font-size: 36px;
//   font-weight: bold;
//   user-select: none;
// }

// .button svg {
//   height: 150px;
//   width: 150px;
//   -webkit-animation-name: rotate;
//   -moz-animation-name: rotate;
//   -ms-animation-name: rotate;
//   -o-animation-name: rotate;
//   animation-name: rotate;
//   -webkit-animation-duration: 5s;
//   -moz-animation-duration: 5s;
//   -ms-animation-duration: 5s;
//   -o-animation-duration: 5s;
//   animation-duration: 5s;
//   -webkit-animation-iteration-count: infinite;
//   -moz-animation-iteration-count: infinite;
//   -ms-animation-iteration-count: infinite;
//   -o-animation-iteration-count: infinite;
//   animation-iteration-count: infinite;
//   -webkit-animation-timing-function: linear;
//   -moz-animation-timing-function: linear;
//   -ms-animation-timing-function: linear;
//   -o-animation-timing-function: linear;
//   animation-timing-function: linear;
// }

// @-webkit-keyframes rotate {
//   from {
//     -webkit-transform: rotate(360deg);
//   }
//   to {
//     -webkit-transform: rotate(0);
//   }
// }
// @-moz-keyframes rotate {
//   from {
//     -moz-transform: rotate(360deg);
//   }
//   to {
//     -moz-transform: rotate(0);
//   }
// }
// @-ms-keyframes rotate {
//   from {
//     -ms-transform: rotate(360deg);
//   }
//   to {
//     -ms-transform: rotate(0);
//   }
// }
// @-o-keyframes rotate {
//   from {
//     -o-transform: rotate(360deg);
//   }
//   to {
//     -o-transform: rotate(0);
//   }
// }
// @keyframes rotate {
//   from {
//     transform: rotate(360deg);
//   }
//   to {
//     transform: rotate(0);
//   }
// }

// .content {
//   display: flex;
//   position: absolute;
//   top: calc(-85vh + 150px);
//   left: calc(-85vw + 150px);
//   width: 70vw;
//   height: 70vh;
//   background-color: var(--blue-400);
//   border-radius: 10px;
//   box-shadow: 0 0 0 100vw rgba(69, 69, 69, 0.9);
//   transition: all 0.5s ease;
//   flex-direction: column;
//   z-index: 1000;
// }

// .content.active {
//   opacity: 1;
//   visibility: visible;
// }

// .content.inactive {
//   opacity: 0;
//   visibility: hidden;
// }

// .exit {
//   position: absolute;
//   cursor: pointer;
//   font-size: 100px;
//   width: 24.75px;
//   height: 24.75px;
//   left: 20px;
//   top: 20px;
// }

// .x {
//   width: 30px;
//   height: 5px;
//   border-radius: 10px;
//   background-color: black;
// }

// .x.top {
//   transform: rotate(45deg);
//   translate: -3px 10px;
// }
// .x.bottom {
//   transform: rotate(-45deg);
//   translate: -3px 5px;
// }

// .content h1 {
//   text-align: center;
// }

// .programs {
//   display: grid;
//   grid-template-columns: repeat(3, 33.33%);
// }

// .program {
//   text-align: center;
// }

.join-section {
  position: fixed;
  bottom: 0;
  width: 100dvw;
  height: 100dvh;
  animation: fade-in 2s ease;
  background-color: rgb(192, 192, 192, 0.8);
  z-index: 10;
  transition: all 0.5s ease;
}

.join-section.active {
  visibility: visible;
  opacity: 1;
}

.join-section.inactive {
  visibility: hidden;
  opacity: 0;
}

.join-border {
  box-sizing: border-box;
  width: calc(var(--join-width) - 50px);
  height: calc(var(--join-height) - 50px);
  margin: 0 25px;
  translate: 0 25px;
  border-radius: 10px;
  border: 5px dotted white;
  position: relative;
  z-index: 99999999;
  display: flex;
  flex-direction: column;
}

.join-container {
  width: var(--join-width);
  height: var(--join-height);
  margin: calc((100dvh - var(--join-height)) / 2)
    calc((100dvw - var(--join-width)) / 2);
  border-radius: 10px;
  background-color: var(--blue-400);
}

.exit {
  cursor: pointer;
  font-size: 100px;
  width: 24.75px;
  height: 24.75px;
  translate: 20px 20px;
}

.x {
  width: 30px;
  height: 5px;
  border-radius: 10px;
  background-color: white;
}

.x.top {
  transform: rotate(45deg);
  translate: -3px 10px;
}
.x.bottom {
  transform: rotate(-45deg);
  translate: -3px 5px;
}

.promotion-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  color: white;
}

.join-container h1 {
  color: white;
  text-align: center;
  font-size: 50px;
  margin-bottom: 0;
}

.promotion-container p {
  font-size: 40px;
}

.promotion-container span {
  color: var(--red-400);
}

.promotion-container a {
  color: white;
  font-size: 50px;
  font-style: italic;
  text-decoration: underline;
  transition: all 0.5s ease;

  &:hover {
    color: var(--red-400);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (width <= 550px) {
  .join-container h1 {
    font-size: 30px;
  }

  .promotion-container p {
    font-size: 25px;
  }

  .promotion-container a {
    font-size: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --join-width: 80dvw;
    --join-height: 80dvh;
  }
}

@media (width > 700px) {
  :root {
    --join-width: 600px;
    --join-height: 800px;
  }
}
