.schedule-section {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.schedule-container {
  font-size: 2rem;
}

.schedule-flex {
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.schedule-container {
  font-size: 30px;
  text-align: center;
}

.picture img {
  width: 100%;
  // aspect-ratio: 16 / 13;
  border-radius: 10px;
}

// .BaskingRidge {
//   background-color: #1d3557;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   // margin-left: 480px;
//   margin-top: 50px;
//   margin-bottom: 5px;
//   color: white;
//   height: 100px;
//   width: 300px;
//   font-size: 2.5rem;
//   border-radius: 40px;
//   font-weight: 500;
//   font-family: "Trebuchet MS";
//   border: solid black 1px;
//   transition: transform 0.3s;
// }
//   .BaskingRidge:hover {
//     background-color: hsl(210, 75%, 30%);
//     transform: scale(1.05);
//     cursor: pointer;
//     ~ .picture1 {
//       opacity: 1;
//       visibility: visible;
//     }
//   }
//   .BaskingRidge:active {
//     background-color: #1d3557;
//   }

//   .picture1:hover {
//     opacity: 1;
//     visibility: visible;
//   }
