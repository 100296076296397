.bottom-section {
  background-color: var(--blue-400);
  height: auto;
  color: white;
}

.bottom-container {
  height: auto;
}

.bottom-flex {
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px;
}

.logo-flex {
  flex-direction: column;
  gap: 10px;
}

.bottom {
  color: white;
}

.bottom-phone-number {
  color: white;
  transition: all 0.5s ease;
}

#pages {
  margin-bottom: 10px;
  text-decoration: underline;
}

.bottom-links-container h3 {
  margin: 0;
  color: white;
  transition: all 0.5s ease;
}

.bottom-links {
  display: flex;
  gap: 10px;
  margin-top: 0;
}

.bottom-phone-number:hover,
.bottom-links-container :not(#pages)h3:hover,
.bottom:hover {
  color: var(--red-400);
}
.copyright {
  text-align: center;
  margin-top: 40px;
}

#copyright {
  margin: 0;
}

#github {
  font-size: 20px;
}

.map-area {
  display: flex;
  gap: 50px;
}

.map-caption {
  margin: 0;
  text-align: center;
}

iframe {
  border: 0;
  border-radius: 10px;
}

@media (max-width: 1070px) {
  .bottom-flex {
    flex-direction: column;
    align-items: center;
  }

  .logo-flex {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
}

@media (max-width: 710px) {
  .map-area {
    flex-direction: column;
  }
}
