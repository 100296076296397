.navbar-section {
  z-index: 5;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100px;
  background-color: white;
  border-bottom: 2px solid black;
}

.navbar-container {
  height: 100%;
}

.navbar-flex {
  height: 100%;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
}

.img-logo {
  height: 60px;
  width: auto;
  margin: auto;
  padding-right: 10px;
}

.top-phone-number {
  transition: all 0.5s ease;
}

.top-phone-number:hover {
  color: var(--red-400);
}

.socials-bar {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 30px;
  margin: 0 10px;
  transition: all 0.5s ease;
}

.sky {
  transition: all 0.5s ease;
  margin-right: 20px;
}

.icon:hover,
.sky:hover {
  color: var(--red-400);
}

@media (max-width: 1120px) {
  .email {
    display: none;
  }
}

@media (width<= 800px) {
  .socials-bar > .links {
    display: none;
  }
}

@media (width<= 485px) {
  .navbar-flex .logo .sky {
    display: none;
  }
}
