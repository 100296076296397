@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&family=Ubuntu&display=swap");

@font-face {
  font-family: "Shadows Into Light";
  src: url("./assets/fonts/ShadowsIntoLight-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu-Regular.ttf") format("ttf");
}

a {
  text-decoration: none;
  color: black;
}

.section {
  width: 100%;
  height: auto;
  padding: 50px 0;
}

.top-section {
  margin-top: 100px;
}

.container {
  box-sizing: border-box;
  max-width: var(--container-width);
  height: auto;
  padding: 0 50px;
  margin: 0 auto;
}

.flex {
  display: flex;
  height: auto;
}

.grid {
  display: grid;
  height: auto;
}

.line {
  outline: 1px solid grey;
}

:root {
  --blue-400: rgb(29, 53, 87);
  --red-400: rgb(230, 57, 70);

  --container-width: 1200px;
}
