.contact-flex {
  justify-self: center;
  align-items: center;
  justify-content: space-between;
}

.picture {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.picture h1 {
  text-align: center;
}
.description {
  font-size: 0.75rem;
  max-width: 700px;
  padding: 45px 20px;
}
#park {
  border-radius: 10px;
  width: 200px;
  // aspect-ratio: 1 / 1;
}

@media (width <= 800px) {
  .contact-flex {
    flex-direction: column;
  }
}
