.pricing-container {
  text-align: center;
  font-size: 30px;
}

.pricing-flex {
  justify-content: center;
  gap: 5px;
}

.pricing-flex img {
  width: 45%;
  border-radius: 10px;
}

@media (width <= 1000px) {
  .pricing-flex {
    flex-direction: column;
    align-items: center;
    img {
      width: clamp(200px, 100%, 500px);
    }
  }
}
