.vids {
  flex-direction: column;
  align-items: center;
  font-size: 150%;
}

.youtube-flex {
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.youtube-flex iframe {
  border-radius: 10px;
  width: 350px;
  aspect-ratio: 9 / 16;
}

@media (585px < width <= 1200px) {
  .youtube-flex iframe {
    width: clamp(150px, 25vw, 350px);
  }
}

@media (max-width: 585px) {
  .youtube-flex {
    flex-direction: column;
    align-items: center;
    // width: clamp(50px, 40vw, 550px);
  }

  .youtube-flex iframe {
    width: 100%;
  }
}
