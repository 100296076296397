.home-section {
  display: flex;
  align-items: center;
  background-image: url("../../assets/images/background2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;

  // background-color: var(--blue-400);
  padding: 0;
  height: auto;
}

.home-flex {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

.home-flex h1 {
  font-size: 65px;
  font-family: "Shadows Into Light", cursive;
  text-align: center;
}

.home-flex h3 {
  margin-top: 0;
  font-size: 35px;
  text-align: center;
}

.location-grid {
  height: auto;
  width: 100%;
  gap: 10%;
  grid-template-columns: 45% 45%;
  place-items: first baseline;
}

.location {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

@media (width <= 485px) {
  .home-flex h1 {
    font-size: clamp(15px, 10vw, 35px);
  }

  .home-flex h2 {
    font-size: clamp(12px, 6vw, 25px);
  }
  .home-flex h3 {
    font-size: clamp(12px, 6vw, 25px);
  }

  .home-flex img {
    height: clamp(150px, 5vw, 250px);
  }
}
