html {
  scroll-behavior: smooth;
  overflow-y: visible;
  overflow-x: hidden;
}

body {
  font: 300 14px/1.4 "Ubuntu", sans-serif;
  background-color: white;
  color: black;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: visible;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
