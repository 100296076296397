.socials-section {
  background-color: var(--blue-400);
  color: white;
}

.cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}

.card {
  display: inline;
  text-align: center;
  font-size: 45px;
  line-height: 75%;
}

.highlight {
  color: red;
}

.meta-text {
  font-size: 20px;
}

.social-btns {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.btn a {
  display: flex;
  align-items: center;
  width: 102px;
  padding: 8px;
  border: 3px solid white;
  border-radius: 10px;
  color: white;
  transition: all 0.5s ease;

  &:hover {
    color: var(--blue-400);
    background-color: white;
  }
}

.socials {
  font-size: 30px;
  margin-right: 8px;
  color: white;
  transition: all 0.5s ease;
}

.btn a:hover .socials {
  color: var(--blue-400);
}

@media (width <= 700px) {
  .cards {
    flex-direction: column;
    gap: 10px;
  }
}

@media (width <=450px) {
  .social-btns {
    flex-direction: column;
    align-items: center;
  }
}
